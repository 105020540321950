/**
 * @author wb-zrq481890
 * @version 1.0.0
 * @Description index
 * @create 2023/5/23
 */
import React, { useEffect, useRef, useMemo } from 'react';
import H265player from '@alife/h265player';
import { CnLoading } from '@alife/cn-ui';
// eslint-disable-next-line import/named
import { isEmpty } from 'src/utils/common';
const LIB_PATH = 'https://g.alicdn.com/sail-fe/h265player/0.0.6/lib/';
function View(props) {
    const { width, height, isLive, autoPlay, sourceURL, autoScale, controlBar, value, } = props;
    const el = useRef();
    const playerRef = useRef(null);
    useEffect(() => {
        if (el.current && isEmpty(value)) {
            el.current.style.background = 'rgba(0, 0, 0, 0.5)';
        }
        if (el.current && !isEmpty(value)) {
            el.current.style.background = 'none';
            playerRef.current = new H265player(el.current, {
                sourceURL: value,
                type: 'HLS',
                libPath: LIB_PATH,
                isLive,
                playBackRate: 1,
                muted: false,
                controlBar,
                maxBufferLength: 6000,
                autoPlay,
                autoScale,
                defaultAlert: false,
            });
        }
        return () => {
            console.log('组件卸载');
            playerRef.current?.destroy();
        };
    }, [autoPlay, autoScale, controlBar, isLive, sourceURL, value]);
    const visible = useMemo(() => {
        return isEmpty(value);
    }, [value]);
    const renderContainer = useMemo(() => {
        return React.createElement("div", { ref: el, style: { width, height } });
    }, [height, width]);
    return (React.createElement("div", null,
        React.createElement(CnLoading, { visible: visible }, renderContainer)));
}
const DWH265playerVideo = View;
DWH265playerVideo.displayName = 'DWH265playerVideo';
export { DWH265playerVideo };
