/* eslint-disable no-useless-concat */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable eqeqeq */
/* eslint-disable no-multi-assign */
/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
// 打印配置

import requestMokelay from './request';

export const getUUID = (len, radix) => {
  const chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  let i;
  radix = radix || chars.length;
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join('');
};

// 打印配置
export const doSetPrinterConfig = (printname) => {
  const request = {
    requestID: getUUID(8, 10),
    version: '1.0',
    cmd: 'setPrinterConfig',
    printer: {
      name: printname,
      needTopLogo: true,
      needBottomLogo: false,
      paperSize: {
        width: 100,
        height: 80,
      },
    },
  };
  window.socket.send(JSON.stringify(request));
};

// 连接
export const doConnect = () => {
  window.socket = new WebSocket('ws://127.0.0.1:13528');
  // 打开Socket
  window.socket.onopen = function (e) {
    // 监听消息
    console.log('Client received a message onopen', e);
    window.socket.onmessage = function (event) {
      console.log('Client received a message onmessage', event);
      const data = JSON.parse(event.data);
      if (data.cmd === 'getPrinters') {
        window.defaultPrinter = data.defaultPrinter;
        doSetPrinterConfig(data.defaultPrinter);
      }
    };
    // 监听Socket的关闭
    window.socket.onclose = function (event) {
      console.log('Client notified socket has closed', event);
    };
  };
};

// 连接
export const doGetPrinters = (request) => {
  window.socket.send(JSON.stringify(request));
};

// 获取设备打印模板
export const getTemplatePath = (templateType, printTemplateInterface) => {
  const templates = {
    facility: {
      url: '/asset/assetFacility/getTemplatePath',
      base: 'https://cloudprint.cainiao.com/template/standard/407781/21',
    },
    space: {
      url: '/asset/assetSpace/getTemplatePath',
      base: 'https://cloudprint.cainiao.com/template/standard/407494/14',
    },
    lowValue: {
      url: '/asset/assetFacility/getTemplatePath',
      base: 'https://cloudprint.cainiao.com/template/standard/407781/21',
    },
  };
  if (templates[templateType].url) {
    return requestMokelay({
      url: printTemplateInterface || templates[templateType].url,
    })
      .then((res) => {
        window.templateURL = res;
        return Promise.resolve(true);
      })
      .catch(() => {
        window.templateURL = templates[templateType].base;
        return Promise.resolve(true);
      });
  } else {
    window.templateURL = templates[templateType].base;
    return Promise.resolve(true);
  }
};

// 打印
export const doSingleCloudPrint = async (
  dataList,
  templateType,
  printTemplateInterface,
) => {
  const success = await getTemplatePath(templateType, printTemplateInterface);
  if (!success) return;
  // 获取打印机
  let request = {
    requestID: getUUID(8, 10),
    version: '1.0',
    cmd: 'getPrinters',
  };
  doGetPrinters(request);
  if (dataList == undefined || dataList.length == 0) {
    console.log('print data is null or undefined!!!');
    return;
  }
  // 模板
  const templateObj = {
    facility() {
      for (let i = 0; i < dataList.length; i++) {
        request = {
          cmd: 'print',
          requetID: getUUID(8, 10),
          version: '1.0',
          task: {
            taskID: getUUID(8, 10),
            preview: false,
            printer: window.defaultPrinter,
            documents: [
              {
                documentID: getUUID(8, 10),
                contents: [
                  // 电子面单部分
                  {
                    templateURL: window.templateURL,
                    data: {
                      facility: {
                        code: dataList[i].code,
                        projectName: dataList[i].projectName,
                        specification: dataList[i].specification,
                        location: dataList[i].location,
                        name: dataList[i].name,
                        facilityType: dataList[i].facilityType,
                        quantity: dataList[i].quantity,
                        unit: dataList[i].unit,
                        parkName: dataList[i].parkName,
                        parkCode: dataList[i].parkCode,
                        parkId: dataList[i].parkId,
                        acceptanceDateStr: dataList[i].acceptanceDateStr,
                        url: dataList[i].url,
                      },
                    },
                  },
                ],
              },
            ],
          },
        };
        window.socket.send(JSON.stringify(request));
      }
    },
    lowValue() {
      for (let i = 0; i < dataList.length; i++) {
        request = {
          cmd: 'print',
          requetID: getUUID(8, 10),
          version: '1.0',
          task: {
            taskID: getUUID(8, 10),
            preview: false,
            printer: window.defaultPrinter,
            documents: [
              {
                documentID: getUUID(8, 10),
                contents: [
                  // 电子面单部分
                  {
                    templateURL: window.templateURL,
                    data: {
                      lowValue: {
                        code: dataList[i].code,
                        location: dataList[i].location,
                        name: dataList[i].name,
                        parkCode: dataList[i].parkCode,
                        parkName: dataList[i].parkName,
                        projectName: dataList[i].projectName,
                        quantity: dataList[i].quantity,
                      },
                    },
                  },
                ],
              },
            ],
          },
        };
        window.socket.send(JSON.stringify(request));
      }
    },
    space() {
      for (let i = 0; i < dataList.length; i++) {
        request = {
          cmd: 'print',
          requetID: getUUID(8, 10),
          version: '1.0',
          task: {
            taskID: getUUID(8, 10),
            preview: false,
            printer: window.defaultPrinter,
            documents: [
              {
                documentID: getUUID(8, 10),
                contents: [
                  // 电子面单部分
                  {
                    templateURL: window.templateURL,
                    data: {
                      space: {
                        code: dataList[i].code,
                        acceptanceDateStr: dataList[i].acceptanceDateStr,
                        projectName: dataList[i].projectName,
                        name: dataList[i].name,
                        parkName: dataList[i].parkName,
                        parkCode: dataList[i].parkCode,
                        parkId: dataList[i].parkId,
                        url: dataList[i].url,
                        buildingArea: dataList[i].buildingArea,
                      },
                    },
                  },
                ],
              },
            ],
          },
        };
        window.socket.send(JSON.stringify(request));
      }
    },
  };
  // 执行打印魔板
  templateObj[templateType]();
};
