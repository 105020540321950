/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
import React from 'react';
// import styles from './index.module.scss';
import { Step, StepItem } from '@alife/cn-ui';
export default function DWSteps(props) {
    const { data, shape = 'circle', onClick = (index) => {
        return index;
    }, } = props;
    const result = (React.createElement("div", null,
        React.createElement(Step, { pure: true, shape: shape }, data.map((item) => {
            return (React.createElement(StepItem, { title: item.title, status: item.status, content: item.content, onClick: onClick }));
        }))));
    return result;
}
DWSteps.displayName = 'DWSteps';
export { DWSteps };
