import React from 'react';
// import { withI18n } from '@alife/panda-i18n';
// import locale from 'src/locale';
import './index.scss';
function View({ src = '', width = '', align = 'left', }) {
    return (React.createElement("div", { style: { textAlign: align } },
        React.createElement("img", { width: width, src: src })));
}
const DWImage = View;
DWImage.displayName = 'DWImage';
export { DWImage };
