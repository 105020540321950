/**
 * @author wb-zrq481890
 * @version 1.0.0
 * @Description index
 * @create 2023/7/4
 */
import React from 'react';
import { Box, CnTextEllipsis } from '@alife/cn-ui';
import styled from 'styled-components';
const IconBox = styled.img `
  width: 40px;
`;
const ItemBox = styled.div `
  margin-bottom: 20px;
`;
const Item = (props) => {
    const { iconUrl, dataSource = {}, itemField = [] } = props;
    return (React.createElement(ItemBox, null,
        React.createElement(Box, { direction: 'row', spacing: 24, align: 'center' },
            iconUrl ? (React.createElement("div", null,
                React.createElement(IconBox, { src: iconUrl, alt: '' }))) : null,
            React.createElement("div", { id: 'dw-box' }, itemField?.map((item) => {
                if (dataSource[item.children] === undefined) {
                    return null;
                }
                return (React.createElement("div", { key: item.children },
                    React.createElement(CnTextEllipsis, { hasTooltip: true, line: 1, toolTipProps: {
                            popupContainer: 'dw-box',
                            v2: true,
                            align: 'l',
                        } }, dataSource[item.children] || '')));
            })))));
};
export default Item;
