/**
 * @author wb-zrq481890
 * @version 1.0.0
 * @Description index
 * @create 2023/7/5
 */
import React from 'react';
import { QRCode } from 'react-qrcode-logo';
const CustomQRCode = (props) => {
    const { size = 150, ecLevel = 'L', value = '', enableCORS = true, quietZone = 10, bgColor = '#FFFFFF', fgColor = '#000000', logoImage = '', logoWidth = 30, logoHeight = 30, logoOpacity = 1, removeQrCodeBehindLogo = false, qrStyle = 'squares', eyeRadius = 0, id = 'react-qrcode-logo', } = props;
    return (React.createElement(QRCode, { size: size, ecLevel: ecLevel, value: value, enableCORS: enableCORS, quietZone: quietZone, bgColor: bgColor, fgColor: fgColor, logoImage: logoImage, logoHeight: logoWidth, logoWidth: logoHeight, logoOpacity: logoOpacity, removeQrCodeBehindLogo: removeQrCodeBehindLogo, qrStyle: qrStyle, eyeRadius: eyeRadius, id: id }));
};
export default CustomQRCode;
