/**
 * @author wb-zrq481890
 * @version 1.0.0
 * @Description index
 * @create 2023/7/3
 */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Notification } from '@alife/cn-ui';
import { useRequest } from 'ahooks';
import NotificationContent from '@/components/dw-notification/notification-content';
import Item from '@/components/dw-notification/item';
import LinkButton from '@/components/dw-notification/link-button';
const win = window;
function View(props) {
    const { requestConfig = {}, _context, type, title, pollingErrorRetryCount, pollingInterval, placement, isShow, iconUrl, itemField, linkParam, link, isOpen, btnText, } = props;
    const [pagination, setPagination] = useState({
        current: 1,
    });
    const timeOutRef = useRef(null);
    const pollingErrorRetryCountRef = useRef(0);
    const requestAsync = useCallback(async (innerParam) => {
        try {
            // makeRequest方法return会一个Promise对象
            const res = await win.cnLowCodeUtils?.request?.makeRequest?.({
                // 请求成功后是否toast提示
                needSuccessToast: false,
                buttonConfig: {
                    options: {
                        requestConfig: {
                            ...requestConfig,
                            params: [
                                {
                                    label: 'page',
                                    value: innerParam?.current || 1,
                                },
                                {
                                    label: 'pageSize',
                                    value: 1,
                                },
                                ...requestConfig?.params,
                            ],
                        },
                    },
                },
                recordDataSource: {},
                state: _context?.state || {},
                // 设置额外请求参数的回调
                handleParams: () => {
                    return {};
                },
            });
            if (res.success && res?.data) {
                if (pollingInterval > 0 && innerParam?.actionType !== 'onChange') {
                    setPagination({ ...pagination, current: 1 });
                    timeOutRef.current = setTimeout(() => {
                        requestAsync({ current: 1 });
                    }, pollingInterval);
                }
                return {
                    total: res?.data?.total || 0,
                    list: res?.data?.data,
                };
            }
            return {
                total: 0,
                list: undefined,
            };
        }
        catch (e) {
            console.error('请求报错', e);
            if (pollingInterval > 0 && innerParam?.actionType !== 'onChange') {
                setPagination({ ...pagination, current: 1 });
                timeOutRef.current = setTimeout(() => {
                    requestAsync({ current: 1 });
                }, pollingInterval);
            }
            pollingErrorRetryCountRef.current += 1;
            if (pollingErrorRetryCountRef.current === pollingErrorRetryCount) {
                clearTimeout(timeOutRef.current);
            }
            return {
                total: 0,
                list: undefined,
            };
        }
    }, [
        _context?.state,
        pagination,
        pollingErrorRetryCount,
        pollingInterval,
        requestConfig,
    ]);
    const { runAsync, data } = useRequest(requestAsync, {
        manual: true,
    });
    const onChange = useCallback((cur) => {
        setPagination({ ...pagination, current: cur });
        runAsync({ current: cur, actionType: 'onChange' });
    }, [pagination, runAsync]);
    useEffect(() => {
        if (win.cnLowCodeUtils?.request) {
            runAsync({ current: 1 });
        }
    }, [runAsync]);
    useEffect(() => {
        if (Array.isArray(data?.list) && data?.list?.length > 0) {
            Notification.config({ placement });
            Notification.open({
                title,
                type,
                content: (React.createElement(NotificationContent, { paginationProps: pagination, onChange: onChange, total: data?.total },
                    React.createElement(Item, { iconUrl: iconUrl, dataSource: data?.list[0], itemField: itemField }),
                    React.createElement(LinkButton, { dataSource: data?.list[0], btnText: btnText, isOpen: isOpen, linkParam: linkParam, link: link }))),
                duration: 0,
                key: 'dw',
            });
        }
    }, [
        btnText,
        data,
        iconUrl,
        isOpen,
        isShow,
        itemField,
        link,
        linkParam,
        onChange,
        pagination,
        placement,
        title,
        type,
    ]);
    // useEffect(() => {
    //   if (isShow) {
    //     Notification.config({ placement });
    //     Notification.open({
    //       title,
    //       type,
    //       content: (
    //         <NotificationContent paginationProps={pagination} onChange={onChange} >
    //           <Item iconUrl={iconUrl} />
    //         </NotificationContent>
    //       ),
    //       duration: 0,
    //       key: 'dw'
    //     })
    //   }
    // }, [iconUrl, isShow, onChange, pagination, placement, title, type]);
    return React.createElement("div", null);
}
const DwNotification = View;
DwNotification.displayName = 'DwNotification';
export { DwNotification };
