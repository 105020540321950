import DetailItem from './detail-item';
import DialogTableCheck from './dialog-table-check';
import Image from './image';
import MergeColumnTable from './merge-column-table';
import Steps from './steps';
import TableStatusColor from './table-status-color';
import Timeline from './timeline';
import ImportTable from './import-table';
import TableCellImg from './table-cell-img';
import TableCellEllipsis from './table-cell-ellipsis';
import Editor from './editor';
import UldInput from './uld-input';
import ArrayTableOcr from './array-table-ocr';
import TablePrintTag from './table-print-tag';
import FileUpload from './file-upload';
import FileShow from './file-show';
import FileImport from './file-import';
// import PrintPreview from './print-preview';
import DWH265playerVideo from './h265player-video';
import DWSlateEditor from './slate-editor';
import DwNotification from './dw-notification';

const bundle = [
  DetailItem,
  DialogTableCheck,
  Image,
  MergeColumnTable,
  Steps,
  TableStatusColor,
  Timeline,
  ImportTable,
  TableCellImg,
  TableCellEllipsis,
  TablePrintTag,
  Editor,
  UldInput,
  ArrayTableOcr,
  FileUpload,
  FileShow,
  FileImport,
  // PrintPreview,
  DWH265playerVideo,
  DWSlateEditor,
  DwNotification,
];

export default bundle;
