/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import styles from './index.module.scss';
export default function DWFileShow(props) {
    const { value } = props;
    // console.log('DWFileShowProps ======>', props);
    // console.log('DWFileShow value ======>', value);
    // if (!readOnly) {
    //   return '编辑态不支持此类型';
    // }
    // const value = [
    //   {
    //     name: 'IMG.jpg',
    //     key: '123456789',
    //     url: 'https://img.alicdn.com/tps/TB19O79MVXXXXcZXVXXXXXXXXXX-1024-1024.jpg',
    //   },
    //   {
    //     name: 'IMG2.jpg',
    //     key: '223456789',
    //     url: 'https://img.alicdn.com/tps/TB19O79MVXXXXcZXVXXXXXXXXXX-1024-1024.jpg',
    //   },
    // ];
    let mapResult = '';
    if (Array.isArray(value) && value.length > 0) {
        mapResult = value.map((val) => {
            return (React.createElement("a", { target: '_blank', className: styles.fileDetail, href: val.url, rel: 'noreferrer' }, val.name));
        });
    }
    return mapResult;
}
DWFileShow.displayName = 'DWFileShow';
export { DWFileShow };
