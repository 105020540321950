/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { CnFilterItem, CnInput, CnAsyncSelect, CnTreeSelect, CnDatePickerPro, } from '@alife/cn-ui';
function FilterItem({ config = [] }) {
    const itemsElement = [];
    config.map((item, index) => {
        const { label, name, type, isHidden = false, props } = item;
        const key = `${type}_${index}`;
        let curItem = React.createElement(React.Fragment, null);
        switch (type) {
            case 'input':
                curItem = (React.createElement(CnFilterItem, { key: key, label: label, name: name },
                    React.createElement(CnInput, { placeholder: `请输入${label}`, ...props })));
                break;
            case 'asyncSelect':
                curItem = (React.createElement(CnFilterItem, { key: key, label: label, name: name },
                    React.createElement(CnAsyncSelect, { ...props })));
                break;
            case 'treeSelect':
                curItem = (React.createElement(CnFilterItem, { key: key, label: label, name: name },
                    React.createElement(CnTreeSelect, { ...props })));
                break;
            case 'datePicker':
                curItem = (React.createElement(CnFilterItem, { key: key, label: label, name: name },
                    React.createElement(CnDatePickerPro, { ...props })));
                break;
            default:
                break;
        }
        if (isHidden) {
            curItem = (React.createElement("p", { style: { height: 0, width: 0, overflow: 'hidden' } }, curItem));
        }
        itemsElement.push(curItem);
    });
    return itemsElement;
}
export default FilterItem;
