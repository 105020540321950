import $i18n from '@alife/panda-i18n';
import React, { useEffect, useState } from 'react';
import { Dialog, Icon, Message } from '@alife/cn-ui';
import QRcode from 'qrcode';
import { doConnect, doSingleCloudPrint } from './print';
import './index.scss';

const Print = (props) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  useEffect(() => {
    if (!props.visible || !props?.prints?.length) return setList([]);
    setLoading(true);
    props
      .getPrintInfo(props.prints)
      .then((data) => {
        setLoading(false);

        setList(data instanceof Array ? data : []);
        if (data?.length) {
          doConnect();
          const resultList = [];
          data.forEach(async (item, index) => {
            const qrCodeUrl = await QRcode.toDataURL(item.url, {
              type: 'image/png', // 类型
              quality: 0.5, // 图片质量A Number between 0 and 1
              width: 100, // 高度
              height: 100, // 宽度
              errorCorrectionLevel: 'L', // 容错率
              margin: 1, // 外边距
              color: {
                dark: '#000000', // 前景色
                light: '#ffffff', // 背景色
              },
            });

            resultList.push({ ...item, qrCodeUrl });
            if (data.length === index + 1) {
              setList(resultList);
            }
          });
        } else {
          return Message.error(
            $i18n.get({
              id: 'TheQueryPrintInformationIsEmpty_1671456169',
              dm: '查询的打印信息为空',
            }),
          );
        }
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible, props.prints]);
  const renderItem = (item) => {
    if (props.printKey === 'facility') {
      return (
        <div className='dialog-print-item'>
          <div className='dialog-print-item-box'>
            <span className='label'>
              {$i18n.get({ id: '31255241601191936.CNTM_NEW', dm: '菜鸟' })}
            </span>
            <span className='value' style={{ fontSize: 14 }}>
              {item.projectName}
            </span>
          </div>
          {/* <div className="dialog-print-item-box"> */}
          {/*  <span className="label">{$i18n.get({ id: 'EquipmentName', dm: '设备名称' })}</span> */}
          {/*  <span className="value">{item.name}</span> */}
          {/* </div> */}
          <div className='dialog-print-item-box'>
            <span className='label'>
              {$i18n.get({
                id: 'src_pages_ZF_components_print_Name',
                dm: '设备名称',
              })}
            </span>
            <span className='value'>{item.name}</span>
          </div>
          <div className='dialog-print-item-box'>
            <span className='label'>
              {$i18n.get({
                id: 'src_pages_ZF_components_print_Equipment ID',
                dm: '设备序号',
              })}
            </span>
            <span className='value'>{item.serialNumber}</span>
          </div>
          <div className='dialog-print-item-box'>
            <span className='label'>
              {$i18n.get({
                id: 'src_pages_ZF_components_print_Installed_Location',
                dm: '所在位置',
              })}
            </span>
            <span className='value'>{item.location}</span>
          </div>
          <div className='dialog-print-item-box-qrCode'>
            <div className='dialog-print-item-box'>
              <div className='dialog-print-item-box full'>
                <span className='label'>
                  {$i18n.get({
                    id: 'src_pages_ZF_components_print_Type_of_equipment',
                    dm: '设备类型',
                  })}
                </span>
                <span className='value'>{item.facilityType}</span>
              </div>
              <div className='dialog-print-item-box full'>
                <span className='label'>
                  {$i18n.get({
                    id: 'src_pages_ZF_components_print_Site_Acceptance_Date',
                    dm: '验收日期',
                  })}
                </span>
                <span className='value'>{item.acceptanceDateStr}</span>
              </div>
              <div className='dialog-print-item-box full'>
                <span className='label'>
                  {$i18n.get({
                    id: 'src_pages_ZF_components_print_Quantity',
                    dm: '数量',
                  })}
                </span>
                <span className='value'>
                  {item.quantity}
                  {item.unit}
                </span>
              </div>
            </div>
            <img src={item.qrCodeUrl} />
          </div>
          <div className='dialog-print-item-box'>
            <span className='label'>
              {$i18n.get({
                id: 'src_pages_ZF_components_print_Asset_ID',
                dm: '资产编码',
              })}
            </span>
            <span className='value'>{item.code}</span>
          </div>
        </div>
      );
    }
    if (props.printKey === 'lowValue') {
      return (
        <div className='dialog-print-item'>
          <div className='dialog-print-item-box-qrCode'>
            <div className='dialog-print-item-box'>
              <div className='dialog-print-item-box full'>
                <span className='label'>
                  {$i18n.get({
                    id: 'src_pages_ZF_components_print_Name',
                    dm: '园区名称',
                  })}
                </span>
                <span className='value'>{item.parkName}</span>
              </div>
              <div className='dialog-print-item-box full'>
                <span className='label'>
                  {$i18n.get({
                    id: 'AssetCode',
                    dm: '资产名称',
                  })}
                </span>
                <span className='value'>{item.name}</span>
              </div>
            </div>
            <img src={item.qrCodeUrl} />
          </div>
        </div>
      );
    }
    if (props.printKey === 'space') {
      return (
        <div className='dialog-print-item'>
          <div className='dialog-print-item-box'>
            <span className='label'>
              {$i18n.get({ id: '31255241601191936.CNTM', dm: '菜鸟' })}
            </span>
            <span className='value'>{item.projectName}</span>
          </div>
          <div className='dialog-print-item-box'>
            <span className='label'>
              {$i18n.get({ id: 'NameOfBuilding', dm: '建筑名称' })}
            </span>
            <span className='value'>{item.name}</span>
          </div>
          <div className='dialog-print-item-box'>
            <span className='label'>
              {$i18n.get({ id: '31255242280669185.CNTM', dm: '建筑面积' })}
            </span>
            <span className='value'>{item.buildingArea}</span>
          </div>
          <div
            className='dialog-print-item-box-qrCode'
            style={{ justifyContent: 'normal' }}
          >
            <div className='dialog-print-item-box' style={{ width: 120 }}>
              <div className='dialog-print-item-box full'>
                <span className='label'>
                  {$i18n.get({ id: 'AcceptanceDate', dm: '验收日期' })}
                </span>
              </div>
              <div className='dialog-print-item-box full'>
                <span className='value'>{item.acceptanceDateStr}</span>
              </div>
            </div>
            <img src={item.qrCodeUrl} />
          </div>
          <div className='dialog-print-item-box'>
            <span className='label'>
              {$i18n.get({ id: 'AssetCode', dm: '资产编码' })}
            </span>
            <span className='value'>{item.code}</span>
          </div>
        </div>
      );
    }
  };
  return (
    <Dialog
      style={{ width: 530 }}
      title={'打印标签'}
      // title={`${props.prints.length > 1 ? $i18n.get({ id: 'Batch', dm: '批量' }) : ''}打印${props.printKey === 'space' ? '位置' : '设备'}标签`}
      visible={props.visible}
      onCancel={props.onCancel}
      onClose={props.onClose}
      onOk={() => {
        doSingleCloudPrint(list, props.printKey, props.printTemplateInterface);
        props.onClose();
      }}
      cancelProps={{
        children: $i18n.get({ id: 'Close', dm: '关闭' }),
      }}
      okProps={{
        children: loading
          ? $i18n.get({ id: 'PrintDataLoading', dm: '打印数据加载中' })
          : $i18n.get({ id: 'ConfirmPrinting', dm: '确定打印' }),
        loading,
      }}
    >
      <div style={{ marginTop: -33 }}>
        <div className='dialog-print-title'>
          <Icon type='warning' size='small' style={{ marginRight: 4 }} />
          {$i18n.get({
            id: 'IfThePrintControlIsNot_147111146',
            dm: '如打印控件未安装！点击这里执行',
          })}
          <a href='https://cloudprint.cainiao.com/cloudprint/client/CNPrintSetup.exe'>
            {$i18n.get({ id: 'Installation', dm: '安装' })}
          </a>
          {$i18n.get({
            id: 'PleaseRefreshThePageOr_175473520',
            dm: '，安装后请刷新页面或重新进入。',
          })}
        </div>
        <div className='dialog-print'>{list.map(renderItem)}</div>
      </div>
    </Dialog>
  );
};

export default Print;
