/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
// import { withI18n } from '@alife/panda-i18n';
// import locale from 'src/locale';
import './index.scss';
import { Box, CnOSSImport, CnDownload, CnTable } from '@alife/cn-ui';
const DownloadComp = CnDownload;
function View(props) {
    const { templateUrl = '', templateName = '', templateDesc = '', uploadUrl = '', uploadDesc = '', createUrl = '', pollingUrl = '', tableDataKey = '', filedValueKey = '', tableColumns = [], value = {}, onChange = (val) => { }, accept = '.xls,.xlsx,.csv', tree = false, } = props;
    const { tableData = [] } = value;
    // const tableColumns = [
    //   {
    //     title: '省份',
    //     key: 'province',
    //   },
    //   {
    //     title: '高中入学率',
    //     key: 'hcrate',
    //   },
    //   {
    //     title: '高考人数',
    //     key: 'joins',
    //   },
    //   {
    //     title: '一本率',
    //     key: 'onerate',
    //   },
    //   {
    //     title: '二本率',
    //     key: 'tworate',
    //   },
    // ];
    // const tableData = [
    //   {
    //     id: 1,
    //     province: '安徽',
    //     joins: 2234324,
    //     onerate: '45%',
    //     tworate: '65%',
    //     hcrate: '87.3%',
    //     width: 200,
    //   },
    //   {
    //     id: 2,
    //     province: '浙江',
    //     joins: 1692388,
    //     onerate: '46%',
    //     tworate: '69.2%',
    //     hcrate: '92.3%',
    //     width: 200,
    //   },
    //   {
    //     id: 3,
    //     province: '山东',
    //     joins: 3823232,
    //     onerate: '55%',
    //     tworate: '72.8%',
    //     hcrate: '78.3%',
    //     width: 200,
    //   },
    //   {
    //     id: 4,
    //     province: '广州',
    //     joins: 123232,
    //     onerate: '55%',
    //     tworate: '72.8%',
    //     hcrate: '78.3%',
    //     width: 200,
    //   },
    //   {
    //     id: 5,
    //     province: '四川',
    //     joins: 823232,
    //     onerate: '55%',
    //     tworate: '72.8%',
    //     hcrate: '78.3%',
    //     width: 200,
    //   },
    // ];
    const [dataSource, setDataSource] = useState(tableData);
    return (React.createElement("div", null,
        React.createElement(Box, { direction: 'row-reverse', align: 'center', spacing: 12, margin: [0, 0, 5, 0] },
            React.createElement(CnOSSImport, { autoImport: true, accept: accept, uploadProps: {
                    service: {
                        url: uploadUrl,
                        method: 'POST',
                    },
                    uploadProps: {
                        description: uploadDesc,
                    },
                }, uploadMsgSlot: React.createElement("div", null,
                    templateUrl ? (React.createElement(React.Fragment, null,
                        "\u8BF7\u4E0B\u8F7D\u00A0",
                        React.createElement(DownloadComp, { buttonProps: {
                                text: true,
                            }, buttonText: templateName, url: templateUrl }),
                        "\u00A0\u6807\u51C6\u683C\u5F0F\u6A21\u677F")) : null,
                    templateDesc ? (React.createElement(React.Fragment, null,
                        templateName ? React.createElement("br", null) : null,
                        templateDesc)) : null), createService: {
                    url: createUrl,
                    method: 'POST',
                }, pollingService: {
                    url: pollingUrl,
                    method: 'POST',
                }, onSuccess: (data) => {
                    // console.log('data========>', data);
                    const filedValue = data[filedValueKey];
                    const tableValue = data[tableDataKey];
                    setDataSource(tableValue);
                    onChange(filedValue);
                } })),
        React.createElement(CnTable, { tree: tree, dataSource: dataSource, columns: tableColumns })));
}
const DWImportTable = View;
DWImportTable.displayName = 'DWImportTable';
export { DWImportTable };
