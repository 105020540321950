import { request } from '@alife/cn-request';

const proxyOuterDomain = (msg, enOne) => {
  if (msg) {
    let url = `${msg}${encodeURIComponent(
      encodeURIComponent(window.parent.location.href),
    )}`;
    if (enOne) {
      url = `${msg}${encodeURIComponent(window.parent.location.href)}`;
    }
    window.parent.location.href = url;
    return true;
  }
  return false;
};

// 魔颗粒接口统一请求
const requestMokelay = (params) => {
  return request(params)
    .then(({ data: res = {} }) => {
      const { msg, errorMsg, data, code } = res || {};
      if (code === '2000' && proxyOuterDomain(msg, false)) return;
      let msgMessage = msg;
      if (msg === '调用成功') msgMessage = '';
      if (data instanceof Array) return data;
      const {
        success = false,
        errorMessage = '',
        module = null,
        message = '',
      } = typeof data === 'object' ? data : {};
      if (!success) {
        throw new Error(
          errorMessage || errorMsg || msgMessage || message || 'Network Error',
        );
      }
      return module;
    })
    .catch((err) => {
      if (!params.noError) {
        // Message.error(err.message);
        console.error(err);
      }
      return Promise.resolve(false);
    });
};

export default requestMokelay;
