import React from 'react';
// import { withI18n } from '@alife/panda-i18n';
// import locale from 'src/locale';
import { CnTable } from '@alife/cn-ui';
import './index.scss';
function View({ dataSource = [], columns = [], }) {
    // dataSource = [
    //   {
    //     id: 1,
    //     province: '安徽',
    //     joins: 2234324,
    //     onerate: '45%',
    //     tworate: '65%',
    //     hcrate: '87.3%',
    //     width: 200,
    //   },
    //   {
    //     id: 2,
    //     province: '浙江',
    //     joins: 1692388,
    //     onerate: '46%',
    //     tworate: '69.2%',
    //     hcrate: '92.3%',
    //     width: 200,
    //   },
    //   {
    //     id: 3,
    //     province: '山东',
    //     joins: 3823232,
    //     onerate: '55%',
    //     tworate: '72.8%',
    //     hcrate: '78.3%',
    //     width: 200,
    //   },
    //   {
    //     id: 4,
    //     province: '广州',
    //     joins: 123232,
    //     onerate: '55%',
    //     tworate: '72.8%',
    //     hcrate: '78.3%',
    //     width: 200,
    //   },
    //   {
    //     id: 5,
    //     province: '四川',
    //     joins: 823232,
    //     onerate: '55%',
    //     tworate: '72.8%',
    //     hcrate: '78.3%',
    //     width: 200,
    //   },
    // ];
    // columns = [
    //   {
    //     title: '省份',
    //     key: 'province',
    //   },
    //   {
    //     title: '高中概况',
    //     children: [
    //       {
    //         title: '高中入学率',
    //         key: 'hcrate',
    //       },
    //       {
    //         title: '高考人数',
    //         key: 'joins',
    //       },
    //     ],
    //   },
    //   {
    //     title: '高中入学率',
    //     key: 'hcrate',
    //   },
    //   {
    //     title: '高考人数',
    //     key: 'joins',
    //   },
    //   {
    //     title: '一本率',
    //     key: 'onerate',
    //   },
    //   {
    //     title: '二本率',
    //     key: 'tworate',
    //   },
    // ];
    return React.createElement(CnTable, { primaryKey: 'id', dataSource: dataSource, columns: columns });
}
const DWMergeColumnTable = View;
DWMergeColumnTable.displayName = 'DWMergeColumnTable';
export { DWMergeColumnTable };
